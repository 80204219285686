class StickyHeader {
  static async init () {
    return new StickyHeader()
  }

  constructor () {
    const $nav = $('.sticky-nav')
    const documentWidth = $(document).width()
    const condition = !$nav.hasClass('style-side') ? documentWidth > 720 : documentWidth > 720 && documentWidth < 992
    const stickOnMobile = $nav.hasClass('stick-on-mobile')

    if (($nav[0] && condition) || stickOnMobile) {
      const navOffset = $nav.offset().top

      window.onscroll = () => {
        if ((window.pageYOffset - 5) >= navOffset) {
          const navOuterHeight = $nav.outerHeight(true)
          $nav.addClass('sticky')
          $('.page-wrapper').css('padding-top', navOuterHeight)
          $('.sticky-gallery').css('top', navOuterHeight + 16)
          $('.sticky-block').css('top', navOuterHeight)
        } else {
          $nav.removeClass('sticky')
          $('.page-wrapper').css('padding-top', 0)
          $('.sticky-gallery, .sticky-block').css('top', 0)
        }
      }
    }
  }
}
export default StickyHeader
